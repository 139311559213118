module.exports = {
    abrirModulo: 'Abrir exame no módulo de',
    adicionarAnexo: 'Adicionar anexo',
    adicionarSecao: 'Adicionar seção',
    agendados: 'Agendados',
    alteracaoLaudoAtalho: 'Alterar o Atalho',
    alteracaoModeloLaudo: 'Alteração de modelo de laudo',
    anexoInserido: 'Anexo registrado',
    atalho: 'Atalho',
    atalhoAtivar: 'Ativar',
    atalhoAtivo: 'Ativo',
    atalhoAtivoDesativoComSucesso: 'Atalho "$" ! com sucesso.',
    atalhoInativar: 'Inativar',
    atalhoInativo: 'Inativo',
    atalhoLaudo: 'Atalhos de Laudo',
    baixandoExame: 'Baixando exame',
    cadastroLaudoAtalho: 'Atalhos para laudo',
    cadastroModeloLaudo: 'Cadastro de modelo de laudo',
    campoNaoPodeSerVazio: 'O Campo não deve começar com espaço',
    carregandoExame: 'Carregando o exame',
    carregandoHistoricoExames: 'Carregando histórico do exame',
    categoria: 'Categoria',
    comLaudo: 'Com laudo',
    complementoLaudo: 'Complemento de laudo',
    confirmacaoAtivarDesativarAtalho: 'Deseja realmente ! o Atalho: "$".',
    confirmaRemocaoAnexo: 'Você confirma a remoção do anexo?',
    confirmaRemocaoModeloLaudo: 'Você confirma a remoção do modelo de laudo?',
    confirmaRemocaoSecaoModeloLaudo: 'Você confirma a remoção da seção?',
    confirmarEscolhaNecessidadeInformarCpfPaciente: 'Você confirma esta escolha? O sistema irá vincular o exame a este paciente e prosseguir com a impressão do protocolo.',
    contraRefercia: 'Contra-referência',
    copiandoLaudo: 'Copiando laudo',
    copiarLaudo: 'Copiar laudo',
    dataHoraAtendimento: 'Data/hora do atendimento',
    descricaoDoAtalho: 'Descrição do atalho',
    descricaoExame: 'Descrição do exame',
    descricaoNecessidadeInformarCpfPaciente: 'Este paciente não possui CPF cadastrado no sistema. Por favor, informe o CPF, clique em "Buscar" e selecione um paciente da listagem para continuar com a impressão do protocolo',
    desejaAdicionarOuSubstituir: 'Adicionar ao modelo atual ou substituir o modelo atual?',
    devolverListaGeral: 'Devolver para lista geral',
    direcionado: 'Direcionado',
    direcionados: 'Direcionado',
    direcionadosAMin: 'Direcionados a mim',
    encaminhamentoRevisao: 'Encaminhamento do laudo para revisão',
    erroAlterarAtalhoAtivo: 'Erro ao alternar ativo do atalho. Por favor, tente novamente.',
    erroCarregarExame: 'Não foi possível carregar o exame',
    erroDownloadWeasis: 'Download do Weasis indisponível. Tente novamente mais tarde.',
    exameAtual: 'Exame atual',
    exameDevolvidoListaGeral: 'Exame devolvido para lista geral',
    examesAgendados: 'Agendados',
    examesComImagens: 'Com imagens',
    exameSelecionado: 'Exame selecionado',
    exameSemImagens: 'Este exame ainda não possui imagens',
    exameSemPermissaoGaleria: 'A visualização de imagens deste exame não é permitida.',
    examesInvalidos: 'Inválidos',
    examesPacs: 'PACS',
    examesSelecionados: 'Exames selecionados',
    examesValidos: 'Válidos',
    exameValidado: 'Exame validado',
    formularioSolicitacaoMed: 'Formulário de solicitação de medicamentos',
    historicoPaciente: 'Histórico do paciente',
    indicacao: 'Indicação',
    indicacaoClinicaRegistrada: 'Indicação clínica registrada',
    invalido: 'Inválido',
    laudoRegistrado: 'Laudo registrado',
    laudosGraves: 'Laudos graves',
    laudoTemporario: 'Laudo temporário',
    medicoExecutor: 'Médico executor',
    mensagemAlteracaoAtalho: 'Deseja alterar o Atalho: "$".',
    modeloLaudo: 'Modelo de laudo',
    modelosLaudoInstituicao: 'Modelo de laudo da instituição',
    modelosLaudoUsuario: 'Modelo de laudo do usuário',
    necessidadeInformarCpfPaciente: 'Informe o CPF do paciente',
    nomeDoAtalho: 'Nome',
    novaRede: 'Nova rede',
    numero_exame: 'Número do exame',
    numeroLaudo: 'Número do laudo',
    ohif: 'OHIF',
    opcoes: 'Opções',
    oQueDesejaFazer: 'O que deseja fazer?',
    outrasInformacoes: 'Outras informações',
    oviyam: 'Oviyam',
    pacienteNaoEncontradoInformarCpf: 'Nenhum paciente foi encontrado com este CPF no sistema ou na base de dados do CADSUS. Você deseja vincular este CPF mesmo assim?',
    pacs: 'PACS',
    pdfIndisponivel: 'A geração do PDF deste exame apresentou erros e não está disponível. Tente novamente. Se o erro persistir, entre em contato com o suporte.',
    pesquisaAvancada: 'Pesquisa avançada',
    pesquisar: 'Pesquisar',
    pesquisaSimples: 'Pesquisa simples',
    placeholderSepareTermos: 'Separe os termos com espaço',
    preparandoEstudo: 'Preparando estudo',
    priorizado: 'Priorizado',
    protocoloMed: 'Protocolo de medicamento',
    qualquerExecutor: 'Qualquer médico executor da instituição',
    qualquerTermo: 'Qualquer um dos termos',
    receitaMedica: 'Receita médica',
    redeAlterada: 'Rede alterada',
    removerSecao: 'Remover seção',
    responsavelEnvio: 'Responsável pelo envio',
    resumo: 'Visualizar resumo',
    resumoLaudo: 'Resumo do laudo',
    rotina: 'Rotina',
    secoesModelo: 'Seções do modelo',
    semImagensExibicao: 'Não há imagens disponíveis para exibição.',
    situacaoOrigem: 'Situação/Origem',
    somenteImagens: 'Somente exames com imagens',
    substituir: 'Substituir',
    sucessoNecessidadeInformarCpfPaciente: 'O paciente foi vinculado com sucesso',
    telediagnostico: 'Telediagnóstico',
    termosDescricao: 'Descrição do exame',
    textoSecao: 'Texto da seção',
    tipoBusca: 'Tipo de busca por termo',
    tituloSecao: 'Título da seção',
    todosTermos: 'Todos os termos',
    trocarRede: 'Trocar rede',
    ultrapassouTempoPrevisto: 'Ultrapassou o tempo previsto para laudo',
    valido: 'Válido',
    visualizacaoPreviaModeloLaudo: 'Visualização prévia do modelo',
    weasis: 'WEASIS'
};